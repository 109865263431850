.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.queue, .pick {
  border: 2px solid greenyellow;
  border-radius: 8px;
  padding: 10px;
  width: fit-content;
  max-width: 80%;
  margin-bottom: 8px;
}

.pick span {
  color: rgb(0, 85, 0);
  font-weight: 600;
}

textarea {
  width: 300px;
  height: 60px;
  border-radius: 10px;
  padding: 10px;
  margin: 4px;
}

textarea:focus {
  outline: darkorchid solid 1px;
  border-color: darkorchid;
}

button {
  margin: 4px;
  padding: 6px;
}

button:hover {
  cursor: pointer;
}